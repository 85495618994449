export default {
  siteTitle: 'Get bonus',
  blogerName: 'INSTA',
  socialsList: [],
  projects: [
    {
      name: 'sol',
      url: 'https://solcasino.life/cdaeb37b7',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c9612cc3b',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/cab398882',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/cd3d14fbb',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs10.com/c3f9bc8e0',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://strd-irit01.com/c5146786d',
      gameTitle: 'All Lucky Clover 5 (BGaming)',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c97c04264',
      gameTitle: 'Royal High-Road (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>For the first deposit</div>',
    },
  ],
  headerText: 'Use promo <strong>INST100</strong> to get 100FS in the slots below!',
  yourPromocode: 'Your promo code',
  gameBonus: 'Bonus in',
  promocode: 'INST100',
  casinoBonus1: '<strong>100% + 500FS</strong><div>For the first deposit</div>',
  casinoBonus2: '<strong>100FS</strong><div>For the sign up with the promo code</div>',
  firstDeposit: 'For the first deposit',
  copyMessage: 'Promo code copied',
  theme: 'dark'
};
